import axios from "axios";
import { mClickHeader } from "@/store/resources/headers.js";
const Urlconvenio = process.env.VUE_APP_CONVENIO;

const state = {
  loadingstatus: false,
  token: sessionStorage.getItem("user") || "",
  status: "",
  usermodal: false,
  updateState: null,
  user: sessionStorage.getItem("user") || null,
  rut: sessionStorage.getItem("rut_usuario_logueado") || null,
  token_temporal: sessionStorage.getItem("token_temporal") || {},
  clave_temporal: sessionStorage.getItem("clave_temporal") || {},
  convenio_url: sessionStorage.getItem("url_convenio") || "",
  canales: JSON.parse(sessionStorage.getItem("canales")) || [],
  canal_selected: sessionStorage.getItem("canal_selected") || "",
  sucursales: JSON.parse(sessionStorage.getItem("sucursales")) || [],
  ambiente: process.env.VUE_APP_AMBIENTE,
  msuite: process.env.VUE_APP_URL_FRONT_MSUITE
};
const actions = {
  putRut({ commit }, rut) {
    sessionStorage.setItem("rut_usuario_logueado", rut);
    commit("putrut", rut);
  },
  // Close dialog

  closeDialog({ commit }) {
    commit("usermodal");
  },

  login({ commit, getters, dispatch }, payload) {
    const path = `/auth`;
    let formData = {
      usuario: payload.username,
      clave: payload.password,
      convenio: getters.GetConvenio,
      returnSecureToken: true,
      modulo: "productos",
    };
    return new Promise((resolve, reject) => {
      axios
        .post(path, formData, { headers: mClickHeader.headers })
        .then(async (response) => {
          let token = response.data.data.token;
          commit("REFRESH_TOKEN", token);

          const userResponse = await dispatch("user");
          userResponse.token = token;
          resolve(userResponse);
        })
        .catch((e) => {
          const error =
            e?.response?.data?.data?.lista_mensaje?.[0]?.mensaje ??
            "Ha ocurrido un error al intentar iniciar sesion.";

          sessionStorage.removeItem("token");
          reject(error);
        });
    });
  },

  user({ commit }) {
    const path = `/usuarios/data`;
    return new Promise((resolve, reject) => {
      axios
        .get(path, { headers: mClickHeader.headers })
        .then((response) => {
          const data = response.data.data;
          commit("SET_USERDATA", data.userData);
          resolve(data);
        })
        .catch(() =>
          reject("No se ha logrado recuperar la información de usuario")
        );
    });
  },

  logout({ commit }) {
    commit("CLEAR_USER_DATA");
    if (state.ambiente === 'MARSH') {
      window.location.href = state.msuite;
    }
  },

  // DEFINE NEW PASSWORD

  updateClave({ commit }, password) {
    return new Promise((resolve, reject) => {
      axios({
        url: "usuarios/clave",
        method: "PUT",
        headers: mClickHeader.headers,
        //headers: { Authorization: `Bearer ${password.token}` },
        data: {
          clave_actual: password.clave_temporal,
          clave_nueva: password.newpassword,
        },
      })
        .then((resp) => {
          commit("loadingstatus", false);
          console.log(resp.data);
          resolve("ok");
        })
        .catch((e) => {
          commit("loadingstatus", false);
          reject(
            e?.response?.data?.data?.lista_mensaje?.[0]?.mensaje ??
              "Ha ocurrido un error al intentar crear la nueva contraseña."
          );
        });
    });
  },

  async GetProfileData({ commit }, rut) {
    axios
      .get(`usuarios/${rut.rut}`, { headers: mClickHeader.headers })
      .then((resp) => {
        commit("SET_USER_PROFILE", resp.data.data.usuario);
      })
      .catch((err) => {
        console.log("errr:", err.response.data);
      });
  },

  async UpdateProfile({ commit }, data) {
    commit("changeUpdateStatus", true);
    axios
      .put(
        "usuarios",
        {
          nombre: data.nombre,
          primer_apellido: data.primer_apellido,
          segundo_apellido: data.segundo_apellido,
          celular: data.celular,
          correo_electronico: data.correo_electronico,
          usuario: data.usuario,
          clave_actual: data.clave_actual,
          clave_nueva: data.clave_nueva,
          foto_perfil: data.foto_perfil,
          extension: data.extension,
          tipo_archivo: "img",
          nombre_archivo: "foto_perfil.png",
        },
        { headers: mClickHeader.headers }
      )
      .then((res) => {
        console.log("res: ", res);
        commit("changeUpdateStatus", false);
      })
      .catch((err) => {
        console.log("error: ", err);
        commit("changeUpdateStatus", false);
      });
  },

  async GetCanales({ commit }) {
    axios
      .get(`convenios/${Urlconvenio}/canales`)
      .then((res) => {
        commit("SET_CANALES", res.data.data.lista_canal);
      })
      .catch((err) => console.log("error: ", err));
  },
  async GetSucursales({ commit }, data) {
    axios
      .get(`convenios/${Urlconvenio}/canales/${data.canal}/sucursales`)
      .then((res) => {
        commit("SET_SUCURSALES", res.data.data.Sucursales);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  },
};

const getters = {
  loadingstatus: (state) => state.loadingstatus,
  usermodal: (state) => state.usermodal,
  isLoggedIn: (state) => !!state.token,
  UserData: (state) => {
    let data;
    try {
      data = JSON.parse(state.user);
    } catch {
      data = state.user || null;
    }
    return data;
  },
  updateStatus: (state) => state.updateState,
  clave: (state) => state.clave_temporal,
  token: (state) => state.token_temporal,
  getCanales: (state) => state.canales,
  canal: (state) => state.canal_selected,
  sucursales: (state) => state.sucursales,
  getRut: (state) => state.rut,
  GetConvenio: (state) => state.convenio_url,
};

const mutations = {
  putrut(state, rut) {
    state.rut = rut;
  },
  loadingstatus(state, newloadingstatus) {
    state.loadingstatus = newloadingstatus;
  },
  changeUpdateStatus(state, newStatus) {
    state.updateState = newStatus;
  },
  usermodal(state, newusermodal) {
    state.usermodal = newusermodal;
  },
  SET_CLAVE_TEMPORAL(state, clave) {
    sessionStorage.setItem("clave_temporal", clave);
    state.clave_temporal = clave;
  },
  SET_TOKEN_TEMPORAL(state, token) {
    sessionStorage.setItem("token_temporal", token);
    state.token_temporal = token;
  },
  SET_USERDATA(state, payload) {
    state.user = payload;
    sessionStorage.setItem("user", JSON.stringify(payload));
  },

  SET_CANALES(state, data) {
    state.canales = data;
    sessionStorage.setItem("canales", JSON.stringify(data));
  },

  SET_CANAL(state, data) {
    state.canal_selected = data;
    sessionStorage.setItem("canal_selected", data);
  },

  SET_SUCURSALES(state, data) {
    state.sucursales = data;
    sessionStorage.setItem("sucursales", JSON.stringify(data));
  },

  CLEAR_USER_DATA() {
    sessionStorage.clear();

    // Remover variables del localStorage
    localStorage.removeItem("produc");
    localStorage.removeItem("solicitudSelected");
    localStorage.removeItem("selectedCompany");
    localStorage.removeItem("selectedValidity");
    location.reload();
  },

  SET_URL_CONVENIO(state, data) {
    state.convenio_url = data;
    sessionStorage.setItem("url_convenio", data);
  },

  REFRESH_TOKEN(state, token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    sessionStorage.setItem("token", token);
    state.token = token;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
