<template>
  <div class="father-div">
    <v-container wrap class="position-relative">
      <v-img
        v-if="company && !mobile"
        :src="company.logo"
        max-width="150"
        height="65"
        contain
        class="product-selected-logo"
      ></v-img>
      <v-expansion-panels accordion focusable flat multiple v-model="panel">
        <v-expansion-panel
          v-for="(item, i) in categories"
          :key="i"
          class="mb-2"
          :id="`section-${item.ref}`"
        >
          <v-divider></v-divider>
          <v-expansion-panel-header
            color="secondary text-h6 primary--text text--darken-4"
          >
            {{ item.text }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- CARENCIAS -->
            <v-container wrap class="pa-2" v-if="item.id == 31">
              <div class="mt-3">
                <h4>Cantidad de meses. Entre 0 y 12 meses</h4>
                <v-row align="center" class="md-12 content-carencias">
                  <v-col cols="3" md="3" lg="3">
                    <v-switch
                      v-model="form.tiene_carencia"
                      color="primary"
                      label="Aplica"
                      @change="(value) => changeCarencia(value)"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="6" lg="6" xl="2">
                    <v-slider
                      :disabled="form.tiene_carencia == false"
                      v-model="form.meses"
                      class="align-center"
                      max="12"
                      min="0"
                      hide-details
                      thumb-label="always"
                      hint="carencia"
                    >
                    </v-slider>
                  </v-col>
                  <span
                    v-if="form.meses <= 1"
                    class="text-h8 font-weight-light"
                    v-text="form.meses + ' Mes'"
                  ></span>
                  <span
                    v-if="form.meses > 1"
                    class="text-h8 font-weight-light"
                    v-text="form.meses + ' Meses'"
                  ></span>
                </v-row>
              </div>
            </v-container>

            <!-- VIGENCIA DE PRODUCTO -->
            <v-container wrap class="pa-2" v-if="item.id == 32">
              <v-row>
                <v-col cols="12" md="12" class="cont-secction">
                  <v-row class="mt-3 align-center">
                    <v-col cols="5" md="5">
                      <h5>Nombre de la vigencia</h5>
                    </v-col>

                    <v-col cols="5" md="4">
                      <h5>Tiempo</h5>
                    </v-col>

                    <v-col cols="2" md="3">
                      <v-btn
                        color="info"
                        text
                        small
                        fab
                        @click="state_modal_Vigencia = true"
                      >
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-fade-transition mode="out-in">
                    <div v-if="Vigencias.length > 0 && !loading.vigencia">
                      <div v-for="(item, i) in Vigencias" :key="i">
                        <v-row class="">
                          <v-col cols="5" md="5">
                            <v-checkbox
                              v-model="form.vigencia"
                              :disabled="item.eliminado"
                              class="global-check check_vigencia"
                              :label="item.vigencia"
                              :value="item"
                              hide-details
                              @change="Check_Vigencia"
                              multiple
                            />
                          </v-col>

                          <v-col cols="5" md="4" class="text_check_vigencia">
                            <span
                              v-if="
                                item.meses <= 1 && item.vigencia != 'Multianual'
                              "
                              class="text-h8 font-weight-light"
                              v-text="item.meses + ' Mes'"
                            ></span>
                            <span
                              v-if="
                                item.meses > 1 && item.vigencia != 'Multianual'
                              "
                              class="text-h8 font-weight-light"
                              v-text="item.meses + ' Meses'"
                            ></span>
                            <span
                              v-if="item.vigencia == 'Multianual'"
                              class="text-h8 font-weight-light"
                              v-text="item.meses + ' Meses'"
                            ></span>
                          </v-col>

                          <v-col cols="2" md="3" v-if="!item.eliminado">
                            <v-btn
                              color="error"
                              x-small
                              text
                              fab
                              class="ml-1"
                              @click="
                                openDelete(
                                  'Vigencia',
                                  6,
                                  item._id,
                                  item.vigencia_descripcion
                                )
                              "
                            >
                              <v-icon small> mdi-close </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <p
                      class="caption primary--text text--darken-4"
                      v-else-if="!loading.vigencia"
                    >
                      <v-icon>mdi-information-outline</v-icon> No se han
                      encontrado Vigencias.
                    </p>
                    <div v-else class="mt-6">
                      <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                      ></v-progress-circular>
                    </div>
                  </v-fade-transition>

                  <div></div>
                </v-col>
              </v-row>
            </v-container>

            <!-- DOCUMENTO QUE SE VA A EMITIR -->
            <v-container wrap class="pa-2" v-if="item.id == 33">
              <v-row class="mt-6">
                <v-col cols="10" md="8">
                  <div>
                    <h4>Documentos que va a emitir al momento de la venta</h4>
                  </div>
                </v-col>

                <v-col cols="2" md="4">
                  <v-btn
                    color="info"
                    small
                    fab
                    text
                    @click="openModalDocumentEmision()"
                  >
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-fade-transition mode="out-in">
                <v-row
                  class="mt-3"
                  dense
                  v-if="DocEmision.length > 0 && !loading.docEmision"
                >
                  <template v-for="(item, i) in DocEmision">
                    <v-col
                      :key="`DE_checkbox_${i}`"
                      cols="10"
                      md="8"
                      v-show="!item.eliminado"
                    >
                      <div class="d-flex align-center">
                        <v-checkbox
                          v-model="form.docEmision"
                          class="mt-0"
                          :label="item.documento_descripcion"
                          :value="item"
                          hide-details
                          multiple
                          required
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="2"
                      md="4"
                      :key="`DE_delete_${i}`"
                      v-show="!item.eliminado"
                    >
                      <v-btn
                        color="error"
                        text
                        fab
                        class="ml-4"
                        x-small
                        @click="
                          openDelete(
                            'Documento emision',
                            8,
                            item._id,
                            item.documento_descripcion
                          )
                        "
                      >
                        <v-icon> mdi-close </v-icon>
                      </v-btn>
                    </v-col>
                  </template>
                </v-row>
                <p
                  class="caption primary--text text--darken-4"
                  v-else-if="!loading.docEmision"
                >
                  <v-icon>mdi-information-outline</v-icon> No se han encontrado
                  Documentos Emitidos.
                </p>
                <div v-else class="mt-6">
                  <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-fade-transition>
            </v-container>

            <!-- DOCUMENTOS QUE SE REQUIERE ADJUNTAR -->
            <v-container wrap class="pa-2" v-if="item.id == 34">
              <v-row class="mt-6">
                <!-- <v-text-field
                  label="Buscar tipos de documentos requeridos"
                  class="mt-2 mb-1 search-Table"
                  append-icon="mdi-magnify"
                  :disabled="true"
                >
                </v-text-field> -->

                <v-col cols="10" md="8">
                  <div>
                    <h4>Documentos que requiere adjuntar</h4>
                  </div>
                </v-col>

                <v-col cols="2" md="4">
                  <v-btn
                    color="info"
                    fab
                    small
                    text
                    @click="state_modal_DocAnexo = true"
                  >
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-fade-transition mode="out-in">
                <v-row
                  class="align-center mt-3"
                  dense
                  v-if="DocAnexo.length > 0 && !loading.docAnexo"
                >
                  <template v-for="(item, i) in DocAnexo">
                    <v-col cols="10" md="8" :key="`doc_anexo_${i}`">
                      <v-checkbox
                        v-model="form.docAnexo"
                        class="mt-0"
                        :label="item.documento_descripcion"
                        :value="item"
                        :disabled="item.eliminado"
                        hide-details
                        multiple
                      />
                    </v-col>
                    <v-col cols="2" md="4" :key="`doc_anexo_delete_${i}`">
                      <v-btn
                        color="error"
                        text
                        fab
                        x-small
                        class="ml-4"
                        v-if="!item.eliminado"
                        @click="
                          openDelete(
                            'Documento anexo',
                            7,
                            item._id,
                            item.documento_descripcion
                          )
                        "
                      >
                        <v-icon small> mdi-close </v-icon>
                      </v-btn>
                    </v-col>
                  </template>
                </v-row>
                <p
                  class="caption primary--text text--darken-4"
                  v-else-if="!loading.docAnexo"
                >
                  <v-icon>mdi-information-outline</v-icon> No se han encontrado
                  Documentos Anexos.
                </p>
                <div v-else class="mt-6">
                  <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-fade-transition>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <form-vigencia
      v-model="state_modal_Vigencia"
      @close="state_modal_Vigencia = false"
      @submit="getVigencia(), (state_modal_Vigencia = false)"
    />
    <modal-doc-anexo
      v-model="state_modal_DocAnexo"
      @close="state_modal_DocAnexo = false"
      @submit="DocumentAnexo(), (state_modal_DocAnexo = false)"
    />
    <modal-doc-emision
      v-model="state_modal_DocEmision"
      @close="state_modal_DocEmision = false"
      @submit="state_modal_DocEmision = false"
    />
    <ModalDelet
      v-if="state_modal_delete"
      @cerrarmodalDelete="cerrarmodalDelete"
      :titulomodal="titulomodal"
      :step_id="step_id"
      :ids="ids"
      :dataDescription="dataDescription"
    />
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      :timeout="timeout"
      :right="x === 'right'"
      :top="y === 'top'"
      :color="color_message"
    >
      <v-icon class="global-icon7 mr-5">{{ icon }}</v-icon>
      {{ text_Menssage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
          X
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import FormVigencia from "@/components/Forms/cruds/vigencia.vue";
import ModalDelet from "@/components/Modal/ModalAlert/Modal-deletConf.vue";
import ModalDocAnexo from "@/components/Modal/ModalProduct/Modal-DocumentAnexo.vue";
import ModalDocEmision from "@/components/Modal/ModalProduct/Modal-DocumentosEmisionV2";

export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: false,
      description: "Variable para definir actualizar el formulario",
    },
    producto: {
      type: [Object, String],
      default: null,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormVigencia,
    ModalDelet,
    ModalDocAnexo,
    ModalDocEmision,
  },
  data() {
    return {
      search: {
        datosCotizacion: "",
        datosEmision: "",
      },
      form: {
        tiene_carencia: false,
        meses: 0,
        vigencia: [],
        docEmision: [],
        docAnexo: [],
        editar: false,
      },
      panel: [],
      DocEmision: [],
      Vigencias: [],
      DocAnexo: [],
      Ventas: [],
      vigenciaMes: "",
      active_vigencia: false,

      state_modal_Vigencia: false,
      state_modal_delete: false,
      state_modal_DocAnexo: false,
      state_modal_DocEmision: false,

      multiLine: true,
      snackbar: false,
      text_Menssage: "",
      y: "top",
      x: "right",
      timeout: 2000,
      color_message: "",
      icon: "",

      loading: {
        vigencia: false,
        docEmision: false,
        docAnexo: false,
      },

      rules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern = /^[1-9]\d*$|^$/;
          return pattern.test(value) || "Campo numerico";
        },
      ],
    };
  },
  computed: {
    ...mapGetters("index", ["getRamoId"]),
    ...mapGetters("NewProduct", ["company"]),
    ...mapState("NewProduct", [
      "plantillas",
      "loadingGetPlantillas",
      "errorGetPlantillas",
    ]),
  },

  async created() {
    let counter = 0;
    this.categories.forEach(() => {
      this.panel.push(counter);
      counter++;
    });
    if (this.editMode) {
      const prod = JSON.parse(localStorage.getItem("produc"));
      const atributos = prod.atributos;
      // this.form.docEmision = atributos.documentos_emision;
      this.form.meses = atributos.meses;
      this.form.tiene_carencia = atributos.tiene_carencia;
      this.form.editar = true;
      this.getIdVigencias(atributos.vigencias);
      this.getIdDocEmit(atributos.documentos_emision);
      this.getIdDocAnex(atributos.documentos_anexo);
      this.get();
    } else {
      this.get();
    }
  },

  mounted() {
    //this.getPlantillasJs();
  },

  methods: {
    ...mapActions("NewProduct", [
      "getVigencias",
      "getDocEmision",
      "getDocAnexo",
      "getDatosVentas",
      "getVigenciaById",
      "getIdDocE",
      "getIdDocA",
      "getPlantillasJsReport",
    ]),
    openModalDocumentEmision(tipo = "Nombre de documento") {
      this.titulomodal = tipo;
      this.state_modal_DocEmision = true;
    },

    getPlantillasJs() {
      this.getPlantillasJsReport();
    },

    cerrarmodalDelete(action, message, color, icon) {
      if (action == false) {
        this.state_modal_delete = false;
      } else {
        this.state_modal_delete = false;
        this.snackbar = true;
        this.color_message = color;
        this.text_Menssage = message;
        this.icon = icon;
        switch (this.step_id) {
          case 6:
            this.getVigencia();
            break;
          case 7:
            this.DocumentAnexo();
            break;
          case 8:
            this.DocumentoEmision();
            break;

          default:
            this.get();
            break;
        }
      }
    },
    openDelete(tipo, step, id, data) {
      this.titulomodal = tipo;
      this.step_id = step;
      this.ids = id;
      this.dataDescription = data;
      this.state_modal_delete = true;
    },

    get() {
      this.getVigencia();
      this.DocumentoEmision();
      this.DocumentAnexo();
      this.DatoVentas();
      if (this.form.tiene_carencia == false) {
        this.form.meses = 0;
      }
    },

    //CARENCIAS
    changeCarencia(value) {
      if (value == true) {
        this.form.tiene_carencia = value;
      } else {
        this.form.tiene_carencia = value;
        this.form.meses = 0;
      }
    },
    meseChage(e) {
      this.form.vigencia.map((a) => {
        if (a.vigencia == "Multianual") {
          return (a.meses = e);
        }
      });
    },

    //VIGENCIAS
    getVigencia() {
      this.loading.vigencia = true;
      this.getVigencias()
        .then((response) => {
          this.Vigencias = response;
        })
        .finally(() => (this.loading.vigencia = false));
    },
    async getIdVigencias(atributos) {
      const data = [];
      for (let i in atributos) {
        data.push(atributos[i]);
      }
      await this.getVigenciaById(data).then((resp) => {
        this.form.vigencia = resp;
      });
      atributos.map((a) => {
        if (a.vigencia == "Multianual") {
          this.active_vigencia = true;
          this.vigenciaMes = a.meses;
        }
      });
    },
    Check_Vigencia(e) {
      e.map((a) => {
        if (a.vigencia == "Multianual") {
          this.active_vigencia = true;
        } else {
          this.active_vigencia = false;
        }
      });
    },

    //DOCUMENTO EMISION
    async DocumentoEmision() {
      this.loading.docEmision = true;
      await this.getDocEmision()
        .then((resp) => {
          this.DocEmision = resp;
        })
        .finally(() => (this.loading.docEmision = false));
    },

    async getIdDocEmit(docEmit) {
      const data = [];
      for (let i in docEmit) {
        data.push(docEmit[i]);
      }
      await this.getIdDocE(data).then((resp) => {
        this.form.docEmision = resp;
      });
    },

    //DOCUMENTO ANEXO
    async DocumentAnexo() {
      this.loading.docAnexo = true;
      await this.getDocAnexo()
        .then((resp) => {
          this.DocAnexo = resp;
        })
        .finally(() => (this.loading.docAnexo = false));
    },
    async DatoVentas() {
      await this.getDatosVentas().then((resp) => {
        this.Ventas = resp;
      });
    },

    async getIdDocAnex(docAnex) {
      const data = [];
      for (let i in docAnex) {
        data.push(docAnex[i]);
      }
      await this.getIdDocA(data).then((resp) => {
        this.form.docAnexo = resp;
      });
    },
  },
};
</script>

<style scoped>
.custom-radio {
  margin-top: 1.25rem;
}
.flex_x {
  margin: -1em !important;
}
.icon_x {
  font-size: 18px;
  color: #bb7d7d;
  margin: -1px 0px 10px 5px;
}
.icon_x:hover {
  background: #c9d4e5;
  border-radius: 50px;
}
.check_vigencia {
  margin-top: 0px;
  padding-top: 0px;
}
.icon_x_vigencia {
  font-size: 18px;
  color: #bb7d7d;
  margin: 13px 0px 10px 5px;
}
.text_check_vigencia {
  color: rgba(0, 0, 0, 0.6);
}
.content-carencias {
  margin-top: 10px;
}
.boton_Agregar {
  z-index: 30;
}
</style>
