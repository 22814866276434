<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card class="pt-2">
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <!-- FORMULARIO DE COMPANIA -->
        <v-form :ref="formRef">
          <h4 class="subtitle-2 mb-1">Informacion de la compañía</h4>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col cols="12" md="6">
              <!-- CAMPO DE EMPRESA -->
              <v-autocomplete
                v-model="form.id_empresa"
                :items="empresas"
                item-text="empresa_descripcion"
                item-value="id"
                label="Empresa"
                dense
                :loading="loadingCompanies"
                :rules="[(v) => !!v || 'Debe seleccionar una empresa.']"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <!-- CAMPO TIPO DE POLIZA -->
              <v-autocomplete
                v-model="form.tipo_poliza"
                :items="policyTypes"
                label="Tipo de póliza"
                item-value="tipo_poliza"
                item-text="poliza_nombre"
                item-disabled="eliminado"
                dense
                multiple
                :loading="loadingPolicy"
                :rules="[
                  (v) => v.length > 0 || 'Campo tipo de poliza requerido.',
                ]"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" small>
                    <span>{{ item?.poliza_nombre ?? "Desconocido" }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+otros {{ form.tipo_poliza.length - 1 }})
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <!-- CAMPO DE DESCRIPCION -->
              <v-text-field
                label="Nombre compañía"
                v-model="form.descripcion"
                dense
                :rules="[(v) => !!v || 'Campo nombre es requerido.']"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <!-- CAMPO CODIGO -->
              <v-text-field
                label="Código de la compañia"
                dense
                v-model="form.codigo"
                :rules="[(v) => !!v || 'Campo código es requerido.']"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <!-- CAMPO TIPO DOCUMENTO -->
              <v-autocomplete
                v-model="form.tipo_documento"
                :items="tipoDocumentos"
                item-text="descripcion"
                item-value="codigo"
                label="Tipo Documento"
                dense
                :loading="loadingCompanies"
                :rules="[(v) => !!v || 'Debe seleccionar una tipo Documento.']"
              ></v-autocomplete>
              <!-- @change="onChangeDocType" -->
            </v-col>

            <v-col v-if="form.tipo_documento === 'RUT'" cols="12" md="6">
              <!-- CAMPO NUMERO DE DOCUMENTO -->
              <v-text-field
                v-model="form.numero_documento"
                label="RUT compañía"
                placeholder="Escriba el numero de RUT de la compañía"
                id="id_rut"
                name="companyrut"
                hint="##.###.###-K"
                :rules="rules.rut"
                dense
              ></v-text-field>
            </v-col>

            <v-col v-else cols="12" md="6">
              <!-- CAMPO NUMERO DE DOCUMENTO -->
              <v-text-field
                v-model="form.numero_documento"
                label="Documento compañía"
                placeholder="Escriba el numero de documento de la compañía"
                id="id_rut"
                name="companyrut"
                dense
              ></v-text-field>
              <!-- hint="##.###.###-K" -->
              <!-- :rules="rules.rut" -->
            </v-col>

            <v-col cols="12" md="6">
              <!-- CAMPO DIAS DE VALIDEZ -->
              <v-text-field
                label="Días de validez"
                v-model="form.dias_validez_cotizacion"
                type="number"
                dense
                :rules="[(v) => !!v || 'Campo días de validez es requerido.']"
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="subtitle-2 mb-1 mt-5">Funciones de la compañía</h4>
          <v-divider class="mb-3"></v-divider>

          <v-row>
            <v-col cols="12" md="6">
              <!-- CAMPO HABILITAR DIAS PAGO -->
              <v-checkbox
                label="Habilitar días de pago"
                v-model="form.dia_pago.disponible"
                :false-value="false"
                :true-value="true"
                hint="Seleccionar días de pago diferentes para esta compañía"
                placeholder="Selecciona los días de pago"
                persistent-hint
                class="mt-0"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" md="6">
              <!-- CAMPO MEDIOS DE PAGO -->
              <v-autocomplete
                v-model="form.medios_pago"
                :items="getPaymentMethodsList"
                item-text="medio_descripcion"
                item-value="id"
                class="mt-2"
                dense
                clearable
                label="Medios de pagos"
                placeholder="Seleccione los medios de pago para la compañía"
                hint="Medios de pago disponible para la compañía"
                :loading="loadingPayments"
                multiple
                persistent-hint
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <!-- CAMPO HABILITAR INTERES ASEGURABLE -->
              <v-checkbox
                label="Interés asegurable"
                v-model="form.interes_asegurable"
                :false-value="false"
                :true-value="true"
                hint="Habilitar el interés asegurable para esta compañía."
                persistent-hint
                class="mt-0"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" md="6">
              <!-- CAMPO DIAS DE PAGO -->
              <v-slide-y-transition>
                <v-autocomplete
                  v-if="form.dia_pago.disponible"
                  dense
                  :items="handlerGetPayDayList()"
                  v-model="form.dia_pago.dias"
                  multiple
                  class="mt-3"
                  label="Días de pago"
                  placeholder="Seleccione los días de pago"
                  chips
                  small-chips
                  deletable-chips
                  clearable
                  :rules="[
                    (v) =>
                      v.length > 0 ||
                      'Debe seleccionar al menos 1 día de pago.',
                  ]"
                ></v-autocomplete>
              </v-slide-y-transition>
            </v-col>
          </v-row>

          <h4 class="subtitle-2 mb-1 mt-3">Archivos adjuntos</h4>
          <v-divider class="mb-5"></v-divider>

          <v-row>
            <v-col cols="12" md="6">
              <!-- CAMPO DE LOGO -->
              <v-file-input
                @change="handlerSetImg"
                counter
                dense
                accept="image/jpeg, image/jpg, image/png"
                label="Adjuntar logo compañia"
                clearable
                :rules="rules.img"
                v-model="fileInput1"
              ></v-file-input>
              <div class="d-flex elevation-1 justify-center mb-3" v-if="imgUrl">
                <v-img :src="imgUrl" height="120" contain />
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <!-- CAMPO DE FIRMA -->
              <v-file-input
                @change="handlerSetImg($event, 'firma')"
                accept="image/jpeg, image/jpg, image/png"
                :rules="rules.img"
                counter
                dense
                clearable
                label="Adjuntar firma"
                v-model="fileInput2"
              ></v-file-input>

              <div
                class="d-flex elevation-1 justify-center mb-3"
                v-if="imgUrlFirma"
              >
              <div
                class="d-flex elevation-1 justify-center mb-3" v-if="imgUrlFirma">
                <v-img :src="imgUrlFirma" height="120" contain />
              </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import {
  validarRut,
  validarRutFormat,
  validarImagen,
} from "@/store/resources/validate.js";
import { rutMask } from "@/store/resources/masks.js";
import { getFileExtension, toBase64 } from "@/store/resources/tools.js";
import FormMixin from "@/Mixins/SetFormMixin.js";

const headers = {
  "x-api-key": process.env.VUE_APP_MCLICK_KEY,
  "jwt-variable": sessionStorage.getItem("jwt-variable"),
};

export default {
  name: "CompanyFormModal",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de Compañías",
      width: 900,
      dayLimit: 28,
      // Loadings
      loadingCompanies: false,
      loadingPolicy: false,
      loadingPayments: false,
      loadingDocumentType: false,
      // FormData
      rut: "",
      form: {
        cambiar_logo: false,
        cambiar_firma: false,
        id_empresa: "",
        descripcion: "",
        codigo: "",
        dias_validez_cotizacion: 1,
        cobertura_descripcion: "sincobertura",
        extension: "",
        nombre_archivo: "",
        interes_asegurable: false,
        tipo_poliza: [],
        archivo: "",
        tipo_documento: "",
        numero_documento: "",
        medios_pago: [],
        dia_pago: {
          disponible: false,
          dias: [],
        },
        firma: {
          extension: "",
          nombre_archivo: "",
          archivo: "",
        },
      },
      empresas: [],
      tipoDocumentos: [],
      // Imagenes
      imgUrl: "",
      jwtvariable: "",
      apiKey: process.env.VUE_APP_MCLICK_KEY,
      ambiente: process.env.VUE_APP_AMBIENTE,
      imgUrlFirma: "",
      // Input Imagenes
      fileInput1: undefined,
      fileInput2: undefined,
    };
  },
  computed: {
    ...mapGetters("Polizas", ["policyTypes"]),
    ...mapGetters("Payment", ["getPaymentMethodsList"]),

    rutMask() {
      return rutMask;
    },
    rules() {
      return {
        rut: [
          (v) => !!v || "El RUT es un campo requerido.",
          (v) => validarRutFormat(v) || "Formato de RUT invalido",
          (v) => validarRut(v) || "No se ha logrado reconocer el RUT",
        ],
        img: [
          (file) =>
            !!file ||
            !!this.formData ||
            "Debe cargar una imagen para continuar.",
          (file) => validarImagen(file),
        ],
      };
    },
  },
  methods: {
    ...mapActions("Payment", ["GetPaymentMethods"]),
    ...mapActions("Integrator", ["getCompanies"]),
    ...mapActions("Polizas", ["REQUEST_POLICY_TYPE"]),
    ...mapActions("Companias", ["getTiposDocumentos"]),

    setup() {
      this.getEmpresa();
      this.getPolicy();
      this.getPayments();
      this.getDocumentType();
      if (this.formData) {
        console.log('🚀 ~ this.formData:', this.formData)
        this.getPictures();
      }
    },
    getToken() {
      console.log('🚀 ~ jwt-variable: ', sessionStorage.getItem('jwt-variable'))
      this.jwtvariable = sessionStorage.getItem('jwt-variable')
    },

    // Listar los dias de pago para la compania.
    handlerGetPayDayList(limit = this.dayLimit) {
      const newList = [];
      for (let index = 1; index <= limit; index++) {
        newList.push(index);
      }
      return newList;
    },

    // Obtener data para los selects del formulario.
    getEmpresa() {
      this.loadingCompanies = true;
      this.getCompanies()
        .then((resp) => {
          this.empresas = resp;
        })
        .finally(() => (this.loadingCompanies = false));
    },
    getPolicy() {
      this.loadingPolicy = true;
      this.REQUEST_POLICY_TYPE()
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => (this.loadingPolicy = false));
    },
    getPayments() {
      this.loadingPayments = true;
      this.GetPaymentMethods().finally(() => (this.loadingPayments = false));
    },
    getDocumentType() {
      this.loadingDocumentType = true;
      this.getTiposDocumentos()
        .then((response) => {
          this.tipoDocumentos = response;
        })
        .finally(() => (this.loadingDocumentType = false));
    },
    getPictures() {
      console.log('🚀 ~ this.formData.logo:', this.formData.logo)
      if (this.formData.logo) {
        this.callImg(this.formData.logo);
      }
      if (this.formData.firma?.url) {
        this.callImgFirma(this.formData.firma?.url);
      }
    },
    // ----

    async handlerSetImg(e, type = "logo") {
      if (e) {
        const imgName = e.name;
        const imgExt = getFileExtension(imgName);
        const file64 = await toBase64(e);
        const imgPrev = URL.createObjectURL(e);

        if (type === "logo") {
          this.form.cambiar_logo = true;
          this.form.extension = imgExt;
          this.form.nombre_archivo = imgName;
          this.form.archivo = file64;
          this.imgUrl = imgPrev;
        }
        if (type === "firma") {
          this.form.cambiar_firma = true;
          this.form.firma.extension = imgExt;
          this.form.firma.nombre_archivo = imgName;
          this.form.firma.archivo = file64;
          this.imgUrlFirma = imgPrev;
        }
      }
    },

    async callImg(url) {
      const { data } = await axios.get(url, { headers });
      this.imgUrl = data.data.url
    },
    async callImgFirma(url) {
      const { data } = await axios.get(url, { headers });
      this.imgUrlFirma = data.data.url;
    },
    /* onChangeDocType(code) {

    }, */

    setter() {
      for (const i in this.formData) {
        if (i in this.form) {
          this.form[i] = this.formData[i];
        }
      }
      // Condiciones especiales para establecer la data.
      this.form.numero_documento = this.formData.numero_documento;
      this.form.id_empresa =
        typeof this.formData.id_empresa === "string"
          ? parseInt(this.formData.id_empresa)
          : this.formData.id_empresa;
    },

    reset() {
      this.form = {
        cambiar_logo: false,
        cambiar_firma: false,
        id_empresa: "",
        descripcion: "",
        codigo: "",
        dias_validez_cotizacion: 1,
        cobertura_descripcion: "sincobertura",
        extension: "",
        nombre_archivo: "",
        interes_asegurable: false,
        tipo_poliza: [],
        archivo: "",
        tipo_documento: "",
        medios_pago: [],
        dia_pago: {
          disponible: false,
          dias: [],
        },
        firma: {
          extension: "",
          nombre_archivo: "",
          archivo: "",
        },
      };
      this.imgUrl = "";
      this.imgUrlFirma = "";
      this.fileInput1 = undefined;
      this.fileInput2 = undefined;
      this.formComponent.resetValidation();
    },
  },
};
</script>
