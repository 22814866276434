import axios from "axios";
import { mClickHeader } from "@/store/resources/headers.js";

export const REQUEST_BRAND_MODELS = ({ commit, getters }, params = {}) => {
  let path = `${getters.getEndpoints.base}?`;
  path += [`limit=${params?.limit ?? 8}`, `page=${params?.page ?? 1}`].join(
    "&"
  );
  if (params?.query) {
    path += `&query=${params.query}`;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(path, mClickHeader)
      .then((response) => {
        const result = response.data.data.result,
          pagination = response.data.data.pagination;
        result.forEach((element) => {
          element.loading = false;
          element.activating = false;
          element.search = "";
        });
        commit("setBrandModelItems", result);
        resolve({
          result,
          pagination,
        });
      })
      .catch(() =>
        reject(
          new Error(
            "Ha ocurrido un error al intentar recuperar las marcas y modelos."
          )
        )
      );
  });
};

// BRAND ACTIONS
// GET
export const REQUEST_BRANDS = ({ commit, getters }, params = {}) => {
  let path = `${getters.getEndpoints.brand}?`;
  path += [`limit=${params?.limit ?? 10}`, `page=${params?.page ?? 1}`].join(
    "&"
  );
  return new Promise((resolve, reject) => {
    axios
      .get(path, mClickHeader)
      .then((response) => {
        const result = response.data.data.result,
          pagination = response.data.data.pagination;
        commit("setBrandItems", result);
        resolve({
          result,
          pagination,
        });
      })
      .catch(() =>
        reject("Ha ocurrido un error al intentar recuperar las marcas.")
      );
  });
};
// POST
export const SAVE_BRAND = ({ getters }, payload) => {
  const path = getters.getEndpoints.brand;
  return new Promise((resolve, reject) => {
    axios
      .post(path, payload, mClickHeader)
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha creado una nueva marca satisfactoriamente!";
        resolve({ message, result });
      })
      .catch(() => reject("Ha ocurrido un error al intentar crear una Marca."));
  });
};
// PUT
export const UPDATE_BRAND = ({ getters }, payload) => {
  const path = `${getters.getEndpoints.brand}/${payload.id}`;
  return new Promise((resolve, reject) => {
    axios
      .put(path, payload.data, mClickHeader)
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha actualizado una marca correctamente!";
        resolve({ message, result });
      })
      .catch(() =>
        reject("Ha ocurrido un error al intentar actualizar una marca.")
      );
  });
};
// ACTIVATE
export const ACTIVATE_BRAND = ({ getters }, payload) => {
  const path = `${getters.getEndpoints.brand}/${payload.id}${getters.getEndpoints.logic}`;
  return new Promise((resolve, reject) => {
    axios({
      url: path,
      method: payload.http,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data?.result,
          message = `Se ha ${
            payload.active ? "activado" : "desactivado"
          } una marca correctamente!`;
        resolve({ message, result });
      })
      .catch(() =>
        reject(
          `Ha ocurrido un error al intentar ${
            payload.active ? "activar" : "desactivar"
          } una marca.`
        )
      );
  });
};

// MODEL ACTIONS //
// POST
export const SAVE_MODEL = ({ getters }, payload) => {
  const path = getters.getEndpoints.model;
  return new Promise((resolve, reject) => {
    axios
      .post(path, payload, mClickHeader)
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha creado un nuevo modelo satisfactoriamente!";
        resolve({ message, result });
      })
      .catch(() => reject("Ha ocurrido un error al intentar crear un modelo."));
  });
};
// PUT
export const UPDATE_MODEL = ({ getters }, payload) => {
  const path = `${getters.getEndpoints.model}/${payload.id}`;
  return new Promise((resolve, reject) => {
    axios
      .put(path, payload.data, mClickHeader)
      .then((response) => {
        const result = response.data.data?.result,
          message = "Se ha actualizado un modelo correctamente!";
        resolve({ message, result });
      })
      .catch(() =>
        reject("Ha ocurrido un error al intentar actualizar un modelo.")
      );
  });
};
// ACTIVATE
export const ACTIVATE_MODEL = ({ getters }, payload) => {
  const path = `${getters.getEndpoints.model}/${payload.id}${getters.getEndpoints.logic}`;
  return new Promise((resolve, reject) => {
    axios({
      url: path,
      method: payload.http,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data?.result,
          message = `Se ha ${
            payload.active ? "activado" : "desactivado"
          } un modelo correctamente!`;
        resolve({ message, result });
      })
      .catch(() =>
        reject(
          `Ha ocurrido un error al intentar ${
            payload.active ? "activar" : "desactivar"
          } un modelo.`
        )
      );
  });
};

export const REPORT = (context, payload) => {
  const path = `/vehiculos/reporte/?query=${payload.search}`;

  return new Promise((resolve, reject) => {
    axios({
      url: path,
      method: "GET",
      headers: mClickHeader.headers,
      responseType: "arraybuffer",
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log("======> ERROR ", error);
        reject(error.response.data?.data?.lista_mensaje[0]?.mensaje);
      });
  });
};
