<template name="component-name">
  <v-container
    fluid
    class="px-0 product-container"
    ref="mainProduct"
    id="id_mainProduct"
  >
    <div
      :class="{ 'd-flex align-center': true, 'flex-wrap': isMobile }"
      id="productTitle"
    >
      <h2 class="primary--text text--darken-4 py-3 px-6">
        {{
          actualizar
            ? producto?.definicion_basica?.nombre_comercial ?? ""
            : tituloPrincipal
        }}
      </h2>
      <v-spacer></v-spacer>
      <v-btn color="warning" text :to="{ name: 'products' }">
        <v-icon left>mdi-arrow-u-left-top</v-icon> Volver a los Productos</v-btn
      >
    </div>

    <v-stepper
      v-model="step"
      class="my-6"
      tile
      style="position: sticky; top: -15px; z-index: 6"
    >
      <v-stepper-header>
        <template v-for="(item, index) of stepperItems">
          <v-stepper-step
            :step="item.id"
            :key="item.id"
            :ref="`step-${index}`"
            :complete="step > index + 1"
          >
            {{ item.label }}
          </v-stepper-step>

          <v-divider
            :key="`divider_${item.id}`"
            v-if="index != stepperItems.length - 1"
          ></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>

    <v-alert v-if="showAler" outlined :type="typeAlert">
      {{ messageAlert }}
    </v-alert>

    <div :class="[isMobile ? 'px-1' : 'px-6']">
      <DefinicionBasica
        v-if="step === 1"
        :categories="stepperItemsDetail.step1.list"
        ref="step-1"
        :editMode="actualizar"
        :mobile="isMobile"
        :static-companies="companiesData"
        @show-extra-form="handlerActivateSection($event)"
      />

      <CoberturasPlanes
        :producto="producto"
        v-else-if="step === 2"
        :categories="stepperItemsDetail.step2.list"
        ref="step-2"
        :mobile="isMobile"
        :editMode="actualizar"
      />

      <Atributos
        :producto="producto"
        v-else-if="step === 3"
        :categories="stepperItemsDetail.step3.list"
        ref="step-3"
        :mobile="isMobile"
        :editMode="actualizar"
      />

      <Tarifas
        :producto="producto"
        v-else-if="step === 4"
        :categories="stepperItemsDetail.step4.list"
        ref="step-4"
        :mobile="isMobile"
        :editMode="actualizar"
      />

      <Certificacion
        :producto="producto"
        v-else-if="step === 5"
        :categories="stepperItemsDetail.step5.list"
        :mobile="isMobile"
        ref="step-5"
        :editMode="actualizar"
      />
      <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
        :timeout="timeout"
        :right="x === 'right'"
        :top="y === 'top'"
        :color="color_message"
      >
        <v-icon class="global-icon7 mr-5">{{ icon }}</v-icon>
        {{ text_Menssage }}

        <template v-slot:action="{ attrs }">
          <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
            X
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <!-- BOTONES DE NAVEGACION -->

    <v-bottom-navigation
      attach="id_mainProduct"
      :style="{
        position: 'sticky',
        bottom: '0',
        'z-index': 2,
        transform: 'translate(0, 12px)',
      }"
      class="elevation-1"
    >
      <v-btn class="mx-6" @click="back()" :disabled="step <= 1"
        >Atras <v-icon>mdi-arrow-left-bold</v-icon></v-btn
      >

      <v-btn
        v-if="step < stepperItems.length"
        @click="next()"
        :disabled="step >= stepperItems.length"
        :loading="loading"
        >Siguiente <v-icon>mdi-arrow-right-bold</v-icon></v-btn
      >

      <v-btn @click="enviar()" v-if="step == 5">Enviar a certificar</v-btn>

      <v-btn
        v-if="step == stepperItems.length"
        @click="enviar()"
        :loading="loading"
        >Finalizar <v-icon>mdi-check-bold</v-icon></v-btn
      >
    </v-bottom-navigation>

    <Navbar
      :items="stepperItemsDetail"
      @goTo="goTo"
      :step="step"
      :progress="progress"
      ref="ProductIndex"
    />

    <!-- BOTON FLOTANTE -->
    <v-btn
      fab
      color="primary"
      class="ml-3"
      bottom
      fixed
      small
      :x-small="$vuetify.breakpoint.xs"
      @click="goUp()"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import Navbar from "@/components/Product/NewProduct/Navbar.vue";
import DefinicionBasica from "@/components/Product/NewProduct/DefinicionBasica/Index.vue";
import CoberturasPlanes from "@/components/Product/NewProduct/CoberturasPlanes/Index.vue";
import Atributos from "@/components/Product/NewProduct/Atributos.vue";
import Tarifas from "@/components/Product/NewProduct/Tarifas.vue";
import Certificacion from "@/components/Product/NewProduct/Certificacion.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";
import { companies } from "@/store/resources/staticData.js";

import Swal from "sweetalert2";

export default {
  props: ["rut", "deficion_Basica"],
  data() {
    return {
      tituloPrincipal: null,
      actualizar: false,
      step: 1,
      progress: 1,
      loading: false,

      pageRef: {
        new: "",
        edit: "EditProduct",
      },
      activateOnEditItems: [19],
      stepperItems: [
        { id: 1, label: "Definición básica", icon: "bi bi-person" },
        { id: 2, label: "Coberturas/Planes", icon: "bi bi-house-door" },
        { id: 3, label: "Atributos", icon: "bi bi-credit-card-2-back-fill" },
        { id: 4, label: "Compañia/Tarifas", icon: "bi bi-gear-wide-connected" },
        // { id: 5, label: "Certificación", icon: "bi bi-pen" },
      ],
      stepperItemsDetail: {
        step1: {
          title: "1. Definición básica",
          list: [
            { id: 11, text: "Compañia", icon: "", ref: "1-1", show: false },
            { id: 111, text: "Codigos", icon: "", ref: "1-11", show: false },
            {
              id: 112,
              text: "Datos Adicionales",
              icon: "",
              ref: "1-12",
              show: false,
            },
            {
              id: 110,
              text: "Corredor de seguro",
              icon: "mdi-folder",
              ref: "1-10",
              show: true,
            },
            { id: 18, text: "Ramo", icon: "mdi-folder", ref: "1-8" },
            { id: 16, text: "Segmento", icon: "", ref: "1-6" },
            { id: 12, text: "Tipo de producto", icon: "", ref: "1-2" },
            { id: 15, text: "Materia asegurada", icon: "", ref: "1-5" },
            { id: 113, text: "Moneda deducible", icon: "", ref: "1-13" },
            { id: 13, text: "Tipo de moneda", icon: "", ref: "1-3" },
            { id: 17, text: "Uso del producto", icon: "", ref: "1-7" },
            { id: 14, text: "Nombre comercial", icon: "", ref: "1-4" },
            {
              id: 19,
              text: "Estado del producto",
              icon: "",
              ref: "1-9",
              show: false,
              disabled: true,
            },
          ],
          form: null,
        },
        step2: {
          title: "2. Coberturas/Planes",
          list: [
            { id: 21, text: "Distribución de la Prima", icon: "", ref: "2-1" },
            { id: 25, text: "Tipo Exclusividad", icon: "", ref: "2-5" },
            { id: 22, text: "Coberturas", icon: "", ref: "2-2" },
            { id: 23, text: "Planes", icon: "", ref: "2-3" },
            { id: 24, text: "Producto asistencia", icon: "", ref: "2-4" },
          ],
          form: null,
        },
        step3: {
          title: "3. Atributos",
          list: [
            { id: 31, text: "Carencias", icon: "", ref: "3-1" },
            { id: 32, text: "Vigencia del producto", icon: "", ref: "3-2" },
            { id: 33, text: "Doc. para Emisión", icon: "", ref: "3-3" },
            { id: 34, text: "Doc. Anexo", icon: "", ref: "3-4" },
            // { id:35, text: "Solicitar Datos", icon: "", ref: "3-5" },
          ],
          form: null,
        },
        step4: {
          title: "4. Textos/Tarifas",
          list: [
            { id: 41, text: "Textos", icon: "", ref: "4-1" },
            { id: 42, text: "Póliza", icon: "", ref: "4-2" },
            { id: 43, text: "Datos del contratante", icon: "", ref: "4-3" },
            { id: 44, text: "Nemotécnico", icon: "", ref: "4-4" },
            { id: 45, text: "Tarifas", icon: "", ref: "4-5" },
          ],
          form: null,
        },
        step5: {
          title: "5. Certificación",
          list: [
            {
              id: 51,
              text: "Certificación",
              icon: "",
              ref: "5-1",
              show: false,
            },
          ],
          form: null,
        },
      },
      data_1Update: [],
      idProduct: null,
      typeAlert: "",
      messageAlert: "",
      showAler: false,

      multiLine: true,
      snackbar: false,
      text_Menssage: "",
      y: "top",
      x: "right",
      timeout: 2000,
      color_message: "",
      icon: "",
    };
  },
  components: {
    DefinicionBasica,
    CoberturasPlanes,
    Atributos,
    Tarifas,
    Certificacion,
    // FirmaDocumentos,
    Navbar,
  },
  computed: {
    ...mapGetters("index", ["getpropuesta", "getProduct"]),

    producto() {
      try {
        return JSON.parse(localStorage.getItem("produc"));
      } catch {
        return localStorage.getItem("produc");
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    companiesData: () => companies,
  },
  created() {
    this.$store.commit("setPage", { page: 10 });
    if (this.$router.history.current.name === this.pageRef.edit) {
      this.progress = 4;
      this.actualizar = true;

      this.tituloPrincipal = "Editar producto";
      this.alternativeSetup();
    } else {
      this.tituloPrincipal = "Crear Nuevo producto";
    }
  },
  methods: {
    ...mapActions("NewProduct", [
      "CreateDataBasica",
      "UpdatedataBasica",
      "Updateplanes",
      "Updatatributos",
      "UpdateTarifas",
      "getProductoID",
    ]),
    ...mapMutations("NewProduct", [
      "setCompanyById",
      "setVigencias",
      "setPlanes",
      "setProductTypeId",
    ]),

    async goTo(refName) {
      const refArray = refName.split("-");
      await this.setStep(parseInt(refArray[0]));
      try {
        const cardElement = this.$refs.mainProduct;
        const subElement = cardElement.querySelector(`#section-${refName}`);
        subElement.scrollIntoView({ behavior: "smooth" });
      } catch (e) {
        console.warn("ancla no encontrada ==>", refName);
      }
    },
    goUp() {
      this.$refs.mainProduct.scrollIntoView({ behavior: "smooth" });
      const cardElement = this.$refs.mainProduct;
      const scrollDuration = 500; // Duración de la animación en milisegundos
      const scrollStep = -cardElement.scrollTop / (scrollDuration / 15); // Cantidad de scroll por cada paso del bucle

      function scrollStepAnimation() {
        const newScrollTop = cardElement.scrollTop + scrollStep;

        if (cardElement.scrollTop > 0) {
          cardElement.scrollTop = newScrollTop;
          requestAnimationFrame(scrollStepAnimation);
        }
      }

      requestAnimationFrame(scrollStepAnimation);
    },
    async setStep(step) {
      await this.setPrevValForm(this.step).then(() => (this.step = step));
      this.setNewValForm(this.step);
    },
    setPrevValForm(val) {
      return new Promise((resolve) => {
        const stepForm = this.$refs[`step-${val}`];
        this.stepperItemsDetail[`step${val}`].form = stepForm.form;
        resolve();
      });
    },
    setNewValForm(val) {
      const stepForm = this.$refs[`step-${val}`];
      const newForm = this.stepperItemsDetail[`step${val}`].form;
      if (newForm) {
        stepForm.form = newForm;
      }
    },
    setIndexStep() {
      const menu = this.$refs.ProductIndex;
      switch (this.step) {
        case 1:
          menu.selectedItem = "1-1";
          break;
        case 2:
          menu.selectedItem = "2-1";
          break;
        case 3:
          menu.selectedItem = "3-1";
          break;
        case 4:
          menu.selectedItem = "4-1";
          break;
        case 5:
          menu.selectedItem = "5-1";
          break;
        default:
          break;
      }
    },
    // BOTON SE SIGUIENTE
    async next() {
      this.loading = true;
      await this.setPrevValForm(this.step);
      // Condiciones antes de ir al siguiente paso (guardar formulario actual)
      await this.step_envio()
        .then((response) => {
          this.color_message = "success";
          this.text_Menssage = response;
          this.icon = "mdi-checkbox-marked-circle";
          this.snackbar = true;

          this.step++;
          // Establecer variantes del nuevo paso
          this.setNewValForm(this.step);
          if (this.step > this.progress) this.progress = this.step;

          this.setIndexStep();
        })
        .catch((error) => {
          if ("info" in error && error.info && error.info !== "") {
            this.messageAlert = error.info;
            this.typeAlert = error.type;
            this.showAler = true;
            setTimeout(() => {
              this.showAler = false;
            }, 6000);
          } else {
            console.error("Error ==>", error);
          }
        })
        .finally(() => {
          this.goUp();
          this.loading = false;
        });
    },
    async back() {
      await this.setPrevValForm(this.step).then(() => this.step--);
      console.log("1", this.setPrevValForm);
      this.setNewValForm(this.step);
      console.log("2", this.setNewValForm(this.step));
      this.goUp();
      console.log("3", this.goUp());
      this.setIndexStep();
      console.log("4", this.setIndexStep());
    },
    alternativeSetup() {
      this.setVigencias(this.producto.atributos.vigencias);
      this.setPlanes(this.producto.coberturas_planes.planes);
      this.setProductTypeId(this.producto.definicion_basica.tipo_producto);
      this.activateSteps(this.activateOnEditItems);
      this.$store.commit("ramosId", this.producto.definicion_basica.ramo);
    },
    activateSteps(items, activationMode = false) {
      let steps = [];
      for (var i in this.stepperItemsDetail) {
        steps = steps.concat(this.stepperItemsDetail[i].list);
      }
      items.forEach((element) => {
        let item = steps.find((e) => e.id == element);
        if (item) {
          item.disabled = activationMode;
        }
      });
    },
    async step_envio() {
      const formData = this.stepperItemsDetail[`step${this.step}`].form;
      return new Promise((resolve, reject) => {
        if (formData) {
          switch (this.step) {
            //PASO NRO1
            case 1: {
              console.clear();
              //GUARDAR DEFINICION BASICA
              const prod = this.producto;
              const payload = {
                copiado: {
                  es_copiado: false,
                  id_producto_copia: "",
                },
                definicion_basica: {
                  ramo: formData.ramo,
                  tipo_producto: formData.producto,
                  tipo_moneda: {
                    pais: formData.moneda.pais,
                    moneda: formData.moneda.valor,
                  },
                  moneda_deducible: {
                    moneda_id: formData.moneda_deducible.moneda_id,
                    moneda_descripcion:
                      formData.moneda_deducible.moneda_descripcion,
                  },
                  codigos_producto: {
                    interno: formData.codigos_producto.interno,
                    externo: formData.codigos_producto.externo,
                  },
                  nombre_comercial: formData.nombreComercial,
                  materia_asegurada: formData.materiaAsegurada,
                  segmento: formData.segmento || "",
                  uso_producto: formData.uso_producto || [],
                  id_compania: formData.id_compania || "",
                  compania: formData.compania || {},
                  corredor: formData.corredor || "",
                  porcentaje_corredor:
                    formData.porcentaje_corredor !== ""
                      ? parseFloat(formData.porcentaje_corredor || "0")
                      : 0,
                },
              };
              // Agregar informacion adicional al payload.
              const { username, password, tarifa } =
                formData.informacion_adicional;
              if (formData.compania.codigo == this.companiesData.hdi.code) {
                payload.definicion_basica.informacion_adicional = { tarifa };
              } else if (
                formData.compania.codigo == this.companiesData.zurich2.code
              ) {
                payload.definicion_basica.informacion_adicional = {
                  username,
                  password,
                };
              }
              // Metodo interno que llama a la peticion de actualizar la definicion basica
              const updateDefBasic = (payload = {}, productId = "") => {
                this.UpdatedataBasica({ id: productId, data: payload }).then(
                  (res) => {
                    if (res.status >= 200 && res.status < 300) {
                      this.getProducto(productId);
                      this.setCompanyById(formData.id_compania);
                      this.setProductTypeId(formData.producto);
                      this.$store.commit("ramosId", formData.ramo);

                      resolve(res.data.data.lista_mensaje[0].mensaje);
                    }
                    if (
                      res.status >= 400 ||
                      ("status" in res.data && !res.data.status) ||
                      ("success" in res.data && !res.data.success)
                    ) {
                      if (res.status === 403) {
                        reject({ info: res.data.message, type: "error" });
                      } else {
                        let message = "Ops, ha ocurrido un Error.";
                        message = res.data.error
                          ? res.data.error
                          : res.data.data.lista_mensaje[0].mensaje;
                        reject({ info: message, type: "error" });
                      }
                    }
                  }
                );
              };

              //EDITAR
              if (formData.editar) {
                //Peticion para actualizar el producto en el paso 1
                this.idProduct = prod.id;
                payload.estado = formData.status || "";
                updateDefBasic(payload, prod.id);
              } else {
                if (this.idProduct == null) {
                  // Peticion para CREAR nuevo producto en el paso 1
                  payload.id_solicitud = this.$store.getters.getSolicitud.id;
                  payload.usuario = this.$store.getters.UserData.usuario;
                  this.CreateDataBasica(payload).then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                      try {
                        const result = res.data.data.result;
                        this.setCompanyById(formData.id_compania);
                        this.setProductTypeId(formData.producto);
                        this.$store.commit("ramosId", formData.ramo);
                        this.$store.commit("productoId", result);
                        this.idProduct = result.id;
                        resolve(
                          "Se ha guardado el producto satisfactoriamente!"
                        );
                      } catch (e) {
                        console.log("Error ==> ", e);
                        reject({ info: e, type: "error" });
                      }
                    }
                    if (
                      res.status >= 400 ||
                      ("status" in res.data && !res.data.status) ||
                      ("success" in res.data && !res.data.success)
                    ) {
                      if (res.status === 403) {
                        reject({ info: res.data.message, type: "error" });
                      } else {
                        let message = "Ops, ha ocurrido un Error.";
                        message = res.data.error
                          ? res.data.error
                          : res.data.data.lista_mensaje[0].mensaje;
                        this.progress = 1;
                        reject({ info: message, type: "error" });
                      }
                    }
                  });
                } else {
                  // Peticion para ACTUALIZAR el producto en el paso 1 (seguido de crearlo)
                  payload.estado = prod.estado;
                  updateDefBasic(payload, this.idProduct);
                }
              }
              break;
            }
            // PASO NRO2
            case 2: {
              //ACTUALIZAR COBERTURAS
              console.clear();
              const payload = {
                data: formData,
                id: formData.editar ? this.producto.id : this.idProduct,
              };

              this.Updateplanes(payload).then((res) => {
                if (res.status == 200) {
                  if (formData.editar === true) {
                    this.getProducto(this.producto.id);
                  }
                  this.setPlanes(formData.plan);
                  resolve(res.data.data.lista_mensaje[0].mensaje);
                }
                if (
                  res.status >= 400 &&
                  (!res.data.status || !res.data.success)
                ) {
                  let message = "Ops, ha ocurrido un error.";
                  message =
                    "error" in res.data && res.data.error
                      ? res.data.error
                      : res.data.data.lista_mensaje[0].mensaje;
                  reject({ info: message, type: "error" });
                }
              });

              break;
            }
            // PASO NRO 3
            case 3: {
              console.clear();
              //ACTUALIZAR ATRIBUTOS
              const payload = {
                data: formData,
                id: formData.editar ? this.producto.id : this.idProduct,
              };
              this.Updatatributos(payload).then((res) => {
                if (res.status == 200) {
                  if (formData.editar === true) {
                    this.getProducto(this.producto.id);
                  }
                  this.setVigencias(formData.vigencia);
                  this.$store.commit("DocEmit", formData.docEmision);
                  resolve(res.data.data.lista_mensaje[0].mensaje);
                }
                if (
                  res.status == 422 ||
                  ("status" in res.data && !res.data.status) ||
                  ("success" in res.data && !res.data.success)
                ) {
                  let message = "Ops, ha ocurrido un error.";
                  message = res.data.error
                    ? res.data.error
                    : res.data.data.lista_mensaje[0].mensaje;
                  reject({ type: "error", info: message });
                }
              });
              break;
            }
            default:
              break;
          }
        } else {
          console.warn("-- No se han encontrado los datos del formulario --");
        }
      });
    },
    async enviar() {
      console.clear();
      //GUARDAR TEXTOS TARIFAS
      this.loading = true;
      const formData = this.$refs[`step-${this.step}`].form;
      if (formData.tipoDeTextoGuardado === "") {
        this.showAler = true;
        this.messageAlert =
          "Debe guardar un tipo de texto, campo texto requerido";
        this.typeAlert = "warning";
        this.goUp();
        setTimeout(() => {
          this.showAler = false;
        }, 6000);
        this.loading = false;
      } else {
        const payload = {
          data: formData,
          id: formData.editar ? this.producto.id : this.idProduct,
        };
        await this.UpdateTarifas(payload)
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              let timerInterval;
              Swal.fire({
                title: "¡El producto ha sido completado satisfactoriamente!",
                html: "Su página será redireccionada en <b></b>...",
                timer: 5000,
                icon: "success",
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const b = Swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft();
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  this.$router.push({ name: "products" });
                }
              });
            }

            if (res.status >= 400) {
              // Regresar el valor a los nemotecnicos en caso de error
              formData.nemotecnico = res.data.nemotecnico;

              // Notificacion
              this.showAler = true;
              try {
                this.messageAlert =
                  res.data.error || res.data.data.lista_mensaje[0].mensaje;
              } catch {
                this.messageAlert =
                  "Ha ocurrido un error al intentar guardar el producto.";
              }
              this.typeAlert = "error";
              this.goUp(); // mover pagina hacia arriba
              setTimeout(() => {
                this.showAler = false;
              }, 6000);
            }
          })
          .finally(() => (this.loading = false));
      }
    },

    async getProducto(id) {
      await this.getProductoID(id).then((resp) => {
        this.$store.commit("Prodcut", resp);
      });
    },

    handlerActivateSection(data = { step: 1, section: 0, value: true }) {
      const selected = this.stepperItemsDetail[`step${data.step}`]?.list?.find(
        (e) => e.id == data.section
      );
      selected.show = data.value;
    },
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar-track {
  background-color: rgba(
    0,
    0,
    0,
    0.15
  ); /* Color de fondo del carril del scroll */
}

::-webkit-scrollbar-button {
  background-color: rgba(0, 0, 0, 0.35);
}
::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
}
.product-container {
  max-height: 91.5vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
