export const mClickHeader = {
  headers: {
    "x-mclick-key": process.env.VUE_APP_MCLICK_KEY,
    "jwt-variable": sessionStorage.getItem("token"),
  },
};

export default {
  mClickHeader,
};
