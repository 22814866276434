<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card>
      <v-card-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-row multi-line>
            <v-col cols="12">
              <v-select
                v-model="form.empresa"
                :items="empresas"
                item-text="empresa"
                item-value="id"
                label="Empresa"
                class="mt-5"
                :rules="[(v) => !!v || 'Debe seleccionar una empresa']"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.homologacion"
                name="homologacion"
                @keyup="uppercase"
                label="Nombre"
                placeholder="Nuevo nombre de homologación"
                :rules="[(v) => !!v || 'Campo nombre requerido']"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.codInt"
                name="codInt"
                label="Código Interno"
                placeholder="Nuevo código interno"
                @keyup="deleteSpaces(1,form.codInt)"
                :rules="[(v) => !!v || 'Campo código int. requerido']"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.codServ"
                name="codServ"
                label="Código Servicio"
                placeholder="Nuevo código de servicio"
                @keyup="deleteSpaces(2,form.codServ)"
                :rules="[(v) => !!v || 'Campo código serv. requerido']"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.descripcion"
                name="descripcion"
                label="Descripción"
                placeholder="Nueva de descripción"
                :rules="[(v) => !!v || 'Campo descripción requerido']"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  mixins: [FormMixin],
  name: "HomologacionForm",
  props: {
    empresas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: true,
      defaultTitle: "Formulario de Homologación",
      form: {
        empresa: "",
        descripcion: "",
        homologacion: "",
        codServ: "",
        codInt: "",
      },
    };
  },
  methods: {
    uppercase() {
      if (this.form.homologacion.length > 0) {
        if (this.form.homologacion.includes(" ")) {
          this.form.homologacion = this.form.homologacion
            .replace(" ", "_")
            .toLowerCase();
        } else {
          this.form.homologacion = this.form.homologacion.toLowerCase();
        }
      }
    },
    deleteSpaces(option,code){
      if (option === 1) {
        this.form.codInt = code.trim()
      } else if (option === 2) {
        this.form.codServ = code.trim()
      }
    },
    setter() {
      this.form.empresa = this.formData.empresa;
      this.form.descripcion = this.formData.descripcion;
      this.form.homologacion = this.formData.nombre;
      this.form.codServ = this.formData.codigo_servicio;
      this.form.codInt = this.formData.codigo_interno;
    },
  },
};
</script>
