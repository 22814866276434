<template>
  <v-container class="not-extended">
    <v-card class="py-1 px-6" color="grey lighten-5">
      <v-card-title class="px-0">
        <!-- BUSCADOR DE MARCAS -->
        <v-text-field
          v-model="search"
          v-bind="bind.search"
          :label="getText.searchBrand.label"
          :placeholder="getText.searchBrand.placeholder"
          v-on="searchOn"
        ></v-text-field>
        <v-divider vertical class="mx-3"></v-divider>
        <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
        <!-- BOTON PARA AGREGAR NUEVA MARCA -->
        <v-btn v-bind="bind.refresh" v-on="on.refresh">
          <v-icon>{{ icons.refresh }}</v-icon>
        </v-btn>
        <!-- BOTON PARA EXPORTAR -->
        <v-btn color="primary" outlined="true" small="true" fab="true" class="mr-3" :loading="this.loadingDownload" @click="this.reporte">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          @click="brand.dialog = true"
          :block="$vuetify.breakpoint.xs"
          :class="{ 'order-first mb-2': $vuetify.breakpoint.xs }"
          >{{ getText.newBrandButton }}<v-icon right>mdi-plus</v-icon></v-btn
        >
      </v-card-title>
      <v-fade-transition>
        <v-progress-linear
          indeterminate
          class="my-1"
          v-if="loading"
        ></v-progress-linear>
      </v-fade-transition>
      <v-data-iterator
        class="elevation-0"
        :items="items"
        :loading="loading"
        :footer-props="dataFooterProps"
        :no-data-text="getText.dataIterator.noData"
        :no-results-text="`${getText.dataIterator.noResult} ${pagination.query}`"
        v-bind="tableBind"
        v-on="tableOn"
      >
        <template v-slot:default="{ items }">
          <v-row class="form-box">
            <v-col
              v-for="(brand, i) in items"
              :key="i"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card>
                <!-- TITULO DE LA MARCA -->
                <v-card-title>
                  <v-badge left small color="primary">
                    <span slot="badge">{{
                      brand?.[getKeys.brand.code] ?? ""
                    }}</span>
                    <!--slot can be any component-->
                    <h4
                      class="subtitle-2"
                      v-if="brand?.[getKeys.brand.description]?.length <= 18"
                    >
                      {{ brand?.[getKeys.brand.description] ?? "--" }}
                    </h4>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <h4 class="subtitle-2" v-on="on" v-bind="attrs">
                          {{
                            brand?.[getKeys.brand.description]?.substring(
                              0,
                              18
                            )
                          }}...
                        </h4>
                      </template>
                      <span>{{ brand?.[getKeys.brand.description] }}</span>
                    </v-tooltip>
                  </v-badge>
                </v-card-title>

                <v-divider></v-divider>

                <!-- BUSCADOR DE MODELOS DE UNA MARCA -->
                <v-card-text>
                  <v-text-field
                    v-model="brand.search"
                    :label="getText.searchModel.label"
                    :placeholder="`${getText.searchModel.placeholder} ${
                      brand?.[getKeys.brand.description] ?? '--'
                    }`"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <p class="mb-0 caption px-2 font-weight-bold pt-2">
                  {{ getText.itemsTitle }}
                </p>
                <!-- LISTADO DE MODELOS DE UNA MARCA -->
                <!-- Filtrado por n cantidad o por un filtro interno de modelos -->
                <v-card-text
                  :style="{
                    height: '235px',
                  }"
                >
                  <v-list-item
                    dense
                    v-for="(model, x) in handlerFilterModels(brand)"
                    :key="x"
                  >
                    <!-- TITULO DEL MODELO -->
                    <v-list-item-content>
                      <v-list-item-title
                        ><small>
                          <v-icon
                            x-small
                            :color="
                              model?.[getKeys.model.active]
                                ? 'grey'
                                : 'success lighten-1'
                            "
                            >mdi-circle</v-icon
                          >
                          {{ model?.[getKeys.model.code] ?? "" }}</small
                        >
                        |
                        {{
                          model?.[getKeys.model.description] ?? rootText.unknown
                        }}</v-list-item-title
                      >
                    </v-list-item-content>

                    <!-- BOTON DE CONFIGURACION DE UN MODELO -->
                    <v-list-item-action>
                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            x-small
                            fab
                            text
                            v-on="on"
                            v-bind="attrs"
                            @click="handlerSelectModel(model, brand)"
                            ><v-icon small>mdi-cog</v-icon></v-btn
                          >
                        </template>
                        <span
                          >{{ getText.modelActions.config }}
                          {{
                            model?.[getKeys.model.description] ??
                            rootText.unknown
                          }}</span
                        >
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item
                    v-if="(brand?.[getKeys.brand.items] ?? []).length <= 0"
                  >
                    <p class="caption mb-0">{{ getText.noModels }}</p>
                  </v-list-item>

                  <!-- Mostrar cuantos mas modelos hay sin mostrar -->
                  <v-list-item
                    v-if="
                      brand.modelos.length > maxModels && brand.search == ''
                    "
                  >
                    <p class="caption">
                      (+{{ brand.modelos.length - maxModels }})
                      {{ getText.itemsTitle }}.
                      <v-btn
                        @click="handlerOpenModelList(brand)"
                        color="warning"
                        x-small
                        text
                        >Ver más</v-btn
                      >
                    </p>
                  </v-list-item>
                </v-card-text>

                <v-divider></v-divider>

                <!-- ACCIONES DE LA MARCA -->
                <v-card-actions class="text-centered">
                  <!-- Activar/Desactivar -->
                  <v-tooltip
                    bottom
                    :color="
                      brand[getKeys.brand.active] ? 'info' : 'grey darken-1'
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        text
                        small
                        :color="brand[getKeys.brand.active] ? 'info' : 'grey'"
                        v-on="on"
                        v-bind="attrs"
                        class="mx-1"
                        :loading="brand.activating"
                        @click="activateBrand(brand)"
                      >
                        <v-icon>{{
                          brand[getKeys.brand.active]
                            ? "mdi-eye-check-outline"
                            : "mdi-eye-off-outline"
                        }}</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >{{
                        brand[getKeys.brand.active]
                          ? getText.brandActions.activate
                          : getText.brandActions.deactivate
                      }}
                      {{ brand?.[getKeys.brand.description] ?? "" }}</span
                    >
                  </v-tooltip>
                  <!-- Editar -->
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        text
                        small
                        color="primary"
                        v-on="on"
                        v-bind="attrs"
                        class="mx-1"
                        @click="handlerSelectBrand(brand)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >{{ getText.brandActions.edit }}
                      {{ brand?.[getKeys.brand.description] ?? "" }}</span
                    >
                  </v-tooltip>
                  <!-- Agregar Modelo -->
                  <v-tooltip bottom color="success">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        text
                        small
                        color="success"
                        v-on="on"
                        v-bind="attrs"
                        class="mx-1"
                        @click="handlerOpenModelDialog(brand)"
                      >
                        <v-icon>mdi-format-list-group-plus</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >{{ getText.brandActions.addModel }}
                      {{ brand?.[getKeys.brand.description] ?? "--" }}</span
                    >
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card>

    <!-- FORMS -->

    <!-- Formulario de Marcas -->
    <v-dialog
      v-model="brand.dialog"
      max-width="600px"
      :persistent="brand.loading"
      @click:outside="handlerResetBrandForm()"
    >
      <validation-observer ref="brandObserver">
        <v-form>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ getText.brandForm.title }}</span>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                x-small
                fab
                text
                :disabled="brand.loading"
                @click="handlerResetBrandForm()"
              >
                <v-icon>{{ icons.close }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="8">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="getText.brandForm.descriptionField.label"
                      rules="required"
                    >
                      <v-text-field
                        v-model="brand.form[getKeys.brand.description]"
                        :label="getText.brandForm.descriptionField.label"
                        required
                        :error-messages="errors"
                        :hint="getText.brandForm.descriptionField.hint"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="getText.brandForm.codeField.label"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="brand.form[getKeys.brand.code]"
                        :error-messages="errors"
                        :label="getText.brandForm.codeField.label"
                        required
                        :hint="getText.brandForm.codeField.hint"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="SaveBrandForm()"
                :loading="brand.loading"
              >
                {{ rootText.buttons.save }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>

    <!-- Formulario de Modelos -->
    <v-dialog
      v-model="model.dialog"
      max-width="600px"
      :persistent="model.loading || model.activateLoading"
      @click:outside="handlerResetModelForm(!modelList.dialog)"
    >
      <validation-observer ref="modelObserver">
        <v-form>
          <v-card>
            <v-card-title class="text-h5">
              {{ getText.modelForm.title }}
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                fab
                x-small
                @click="handlerResetModelForm(!modelList.dialog)"
                :disabled="model.loading || model.activateLoading"
              >
                <v-icon>{{ icons.close }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <p class="text-h5 font-weight-bold text-center mb-0">
                      {{ brand.selected?.[getKeys.brand.description] ?? "" }}
                    </p>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="getText.modelForm.descriptionField.label"
                      rules="required"
                    >
                      <v-text-field
                        v-model="model.form[getKeys.model.description]"
                        :label="getText.modelForm.descriptionField.label"
                        required
                        :error-messages="errors"
                        :hint="getText.modelForm.descriptionField.hint"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="getText.modelForm.codeField.label"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="model.form[getKeys.model.code]"
                        :error-messages="errors"
                        :label="getText.modelForm.codeField.label"
                        required
                        :hint="getText.modelForm.codeField.hint"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="model.selected"
                :color="
                  model.selected[getKeys.model.active] ? 'success' : 'grey'
                "
                text
                @click="activateModel()"
                :loading="model.activateLoading"
                :disabled="model.loading"
              >
                {{
                  model.selected[getKeys.model.active]
                    ? rootText.buttons.activate
                    : rootText.buttons.deactivate
                }}
              </v-btn>
              <v-btn
                color="primary"
                @click="SaveModelForm()"
                text
                :loading="model.loading"
                :disabled="model.activateLoading"
              >
                {{ rootText.buttons.save }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>

    <!-- MODEL MODAL -->
    <v-dialog v-model="modelList.dialog" scrollable max-width="500px">
      <v-card>
        <v-card-title
          >Listado Modelos
          {{ brand.selected?.[getKeys.brand.description] ?? "" }}</v-card-title
        >
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-text-field
            label="Buscar modelo..."
            dense
            hide-details
            v-model="modelList.search"
            class="mb-3"
          ></v-text-field>

          <v-data-table
            :headers="modelList.headers"
            :items="brand.selected?.[getKeys.brand.items] ?? []"
            :search="modelList.search"
          >
            <template #item.actions="{ item }">
              <v-btn
                color="primary"
                fab
                x-small
                text
                @click="handlerSelectModel(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="handlerResetBrandForm(), (modelList.dialog = false)"
          >
            {{ rootText.buttons.close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageMixin from "@/Mixins/SetPageMixin.js";
import PaginationMixin from "@/Mixins/PaginationMixin.js";

export default {
  name: "BrandAndModelCrud",
  mixins: [PageMixin, PaginationMixin],
  data: () => ({
    maxModels: 3,
    dataFooterProps: {
      "items-per-page-options": [2, 4, 8, 12, 16, 32],
    },
    pagination: {
      objects: 0,
      page: 1,
      pages: 1,
      limit: 8,
    },
    brand: {
      selected: null,
      dialog: false,
      loading: false,
      form: {
        marca_descripcion: "",
        marca: "",
      },
    },
    model: {
      selected: null,
      dialog: false,
      loading: false,
      activateLoading: false,
      form: {
        modelo_descripcion: "",
        modelo: "",
        marca: "",
      },
    },
    modelList: {
      search: "",
      dialog: false,
      headers: [
        { text: "Descripción", value: "modelo_descripcion", sortable: true },
        { text: "Código", value: "modelo", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
    },
    loadingDownload: false,
  }),
  computed: {
    ...mapGetters("MarcasModelos", [
      "getBrandModelsItems",
      "getKeys",
      "getText",
    ]),

    items() {
      return this.getBrandModelsItems;
    },

    rootText() {
      return this.$store.getters.GetText;
    },
  },
  methods: {
    ...mapActions("MarcasModelos", [
      "REQUEST_BRAND_MODELS",
      "SAVE_BRAND",
      "UPDATE_BRAND",
      "ACTIVATE_BRAND",
      "SAVE_MODEL",
      "UPDATE_MODEL",
      "ACTIVATE_MODEL",
      "REPORT"
    ]),

    async setup() {
      this.loading = true;
      await this.getItems();
      this.loading = false;
    },

    async reporte(){
      this.loadingDownload = true;
      this.REPORT({
        search: this.search,
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "reporte.xlsx");
        // Nombre del archivo a descargar
        document.body.appendChild(link);
        link.click();
        // Limpiar URL para liberar memoria
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => {
        this.snackbar(e);
      })
      .finally(() => {
        this.loadingDownload = false;
      });
    },

    getItems() {
      return new Promise((resolve) => {
        this.REQUEST_BRAND_MODELS({ ...this.pagination, query: this.search })
          .then((response) => {
            const pagination = response.pagination;
            this.setPaginationFromResponse(pagination);
            resolve();
          })
          .catch((message) => {
            this.snackbar(message);
            resolve();
          });
      });
    },

    //--BRAND METHODS--/

    handlerSelectBrand(item) {
      const keys = this.getKeys.brand;
      this.brand.selected = item;
      this.brand.form[keys.description] = item[keys.description];
      this.brand.form[keys.code] = item[keys.code];
      this.brand.dialog = true;
    },

    SaveBrandForm() {
      const keys = this.getKeys.brand;
      this.$refs.brandObserver.validate().then((isValid) => {
        if (isValid) {
          this.brand.loading = true;
          let METHOD;
          let formData;
          if (this.brand.selected) {
            formData = {
              id: this.brand.selected[keys.id],
              data: this.brand.form,
            };
            METHOD = this.UPDATE_BRAND;
          } else {
            formData = this.brand.form;
            METHOD = this.SAVE_BRAND;
          }
          METHOD(formData)
            .then((response) => {
              this.$store.commit("setSnackbar", {
                active: true,
                color: "success",
                top: true,
                right: true,
                text: response.message,
              });
              this.handlerResetBrandForm();
              this.setup();
            })
            .catch((message) => {
              this.$store.commit("setSnackbar", {
                active: true,
                color: "error",
                top: true,
                right: true,
                text: message,
              });
            })
            .finally(() => (this.brand.loading = false));
        }
      });
    },

    activateBrand(item) {
      const keys = this.getKeys.brand;
      item.activating = true;
      this.ACTIVATE_BRAND({
        id: item[keys.id],
        active: item[keys.active],
        http: item[keys.active] ? "PUT" : "DELETE",
      })
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            color: item[keys.active] ? "success" : "primary",
            top: true,
            right: true,
            text: response.message,
          });
          this.setup();
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            color: "error",
            top: true,
            right: true,
            text: message,
          });
        });
    },

    handlerResetBrandForm() {
      const keys = this.getKeys.brand;
      this.brand.form[keys.description] = "";
      this.brand.form[keys.code] = "";
      this.brand.selected = null;
      this.model.form[this.getKeys.model.main] = "";
      this.brand.dialog = false;
    },

    //--MODELS METHODS--//
    handlerFilterModels(brand) {
      return (brand?.[this.getKeys.brand.items] ?? [])
        .filter(
          (e) =>
            (e?.[this.getKeys.model.code] ?? "")
              .toString()
              .toLowerCase()
              .includes(brand.search.toLowerCase()) ||
            (e?.[this.getKeys.model.description] ?? "")
              .toLowerCase()
              .includes(brand.search.toLowerCase())
        )
        .slice(0, this.maxModels);
    },

    handlerOpenModelDialog(item = null) {
      this.brand.selected = item;
      this.model.form[this.getKeys.model.main] = item[this.getKeys.brand.id];
      this.model.dialog = true;
    },

    handlerSelectModel(model = null, brand = null) {
      const keys = this.getKeys.model;
      if (brand) {
        this.brand.selected = brand;
        this.model.form[keys.main] =
          model[keys.main] ?? brand[this.getKeys.brand.id];
      }
      this.model.selected = model;
      this.model.form[keys.description] = model[keys.description];
      this.model.form[keys.code] = model[keys.code];
      this.model.dialog = true;
    },

    SaveModelForm() {
      const keys = this.getKeys.model;
      this.$refs.modelObserver.validate().then((isValid) => {
        if (isValid) {
          this.model.loading = true;
          let METHOD;
          let formData;
          if (this.model.selected) {
            formData = {
              id: this.model.selected[keys.id],
              data: this.model.form,
            };
            METHOD = this.UPDATE_MODEL;
          } else {
            formData = this.model.form;
            METHOD = this.SAVE_MODEL;
          }
          METHOD(formData)
            .then((response) => {
              this.$store.commit("setSnackbar", {
                active: true,
                color: "success",
                top: true,
                right: true,
                text: response.message,
              });
              this.handlerResetModelForm(!this.modelList.dialog);
              this.setup();
            })
            .catch((message) => {
              this.$store.commit("setSnackbar", {
                active: true,
                color: "error",
                top: true,
                right: true,
                text: message,
              });
            })
            .finally(() => (this.model.loading = false));
        }
      });
    },

    activateModel() {
      const keys = this.getKeys.model;
      const item = this.model.selected;

      this.ACTIVATE_MODEL({
        id: item[keys.id],
        active: item[keys.active],
        http: item[keys.active] ? "PUT" : "DELETE",
      })
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            color: item[keys.active] ? "success" : "primary",
            top: true,
            right: true,
            text: response.message,
          });
          this.model.selected[keys.active] = !item[keys.active];
          this.setup();
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            color: "error",
            top: true,
            right: true,
            text: message,
          });
        });
    },

    handlerResetModelForm(deleteBrand = true) {
      const keys = this.getKeys.model;
      if (deleteBrand) {
        this.model.form[keys.main] = "";
        this.brand.selected = null;
      }
      this.model.form[keys.description] = "";
      this.model.form[keys.code] = "";
      this.model.selected = null;
      this.model.dialog = false;
    },

    handlerOpenModelList(item) {
      this.brand.selected = item;
      this.model.form[this.getKeys.model.main] = item[this.getKeys.brand.id];
      this.modelList.dialog = true;
    },
  },
};
</script>
