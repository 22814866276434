import { render, staticRenderFns } from "./NewProduct.vue?vue&type=template&id=7551cda9&scoped=true&name=component-name"
import script from "./NewProduct.vue?vue&type=script&lang=js"
export * from "./NewProduct.vue?vue&type=script&lang=js"
import style0 from "./NewProduct.vue?vue&type=style&index=0&id=7551cda9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7551cda9",
  null
  
)

export default component.exports