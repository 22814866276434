<template>
  <v-dialog v-model="value" width="720" @click:outside="$emit('close')">
    <v-card class="container-modal">
      <v-card-title primary-title>
        {{ empresa?.empresa ?? "" }}
        <v-spacer></v-spacer>
        <v-btn color="primary" text fab x-small
          ><v-icon @click="$emit('close')">mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form>
          <v-file-input
            v-if="!loading"
            v-model="file"
            accept=".xlsx"
            prepend-icon="mdi-cloud-upload"
            @change="haveFile"
          ></v-file-input>
          <v-layout v-else justify-center>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
              <v-radio-group
                class="pl-4 mt-0"
                v-for="(option, i) in tiposCarga"
                :key="i"
                v-model="tipo_carga"
                row
              >
                <v-radio
                  :label="option.descripcion"
                  :value="option.valor"
                ></v-radio>
              </v-radio-group>
          <v-col cols="12" class="pb-8">
            <v-row no-gutters>
              <v-btn v-if="tipo_carga === 'CREATE'" outlined color="primary" class="ml-3" @click="downloadFile">
              Plantilla
              <v-icon right>mdi-download</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ml-3"
              :disabled="haveDocument"
              @click="sendFile"
            >
              Cargar archivo
              <v-icon right>mdi-upload</v-icon></v-btn
            >
            </v-row>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    empresa: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
    tiposCarga: {
      type: Array,
      default: () => [],
    },
    codigosCarga: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      file: [],
      responseFile: [],
      loading: false,
      haveDocument: true,
      tipo_carga: "CREATE",
    };
  },

  methods: {
    ...mapActions("Integrator", ["CargaMasiva"]),

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.onload = () => {
            var fileData = reader.result.toString().split(",");
            let myFile = fileData[1];
            resolve(myFile);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        } catch (e) {
          reject(false);
        }
      });
    },

    haveFile(item) {
      if (item != null) {
        this.haveDocument = false;
      } else {
        this.haveDocument = true;
      }
    },

    async sendFile() {
      this.loading = true;
      let base64 = await this.toBase64(this.file);
      let datos = {
        empresa: `${this.empresa.id}`,
        archivoBase64: base64,
        tipo_carga: this.tipo_carga,
      };     
      await this.CargaMasiva(datos)
        .then((response) => {
          //Descarga documento de respuesta a carga masiva
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(response?.result);
          downloadLink.download = `homologaciones_${this.empresa.empresa_descripcion}_${new Date()}.xlsx`; 
          downloadLink.click();
          URL.revokeObjectURL(downloadLink.href);
          this.$store.commit("setSnackbar", {
            active: true,
            color: "success",
            top: true,
            right: true,
            text: response.info,
          });
          this.file = [];
          this.$emit("submit");
        })
        .catch((error) => {
          const message =
            error.result?.data?.lista_mensaje?.[0]?.mensaje ?? error.info;
          this.$store.commit("setSnackbar", {
            active: true,
            color: "error",
            top: true,
            right: true,
            text: message,
          });
        })
        .finally(() => {
          this.loading = false;
          this.tipo_carga = "CREATE";
        });
    },

    downloadFile() {
      const url = "/docs/Cargamasiva.xlsx";
      window.location.href = url;
    },
  },
};
</script>

<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";

#flex {
  margin-left: -90%;
}
</style>
